import { Title } from '@solidjs/meta';
import { Heading, Container, Section, Picture, LinkButton, HorizontalRule, Page, Carousel } from '@troon/ui';
import { For, Switch, Match } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Icon } from '@troon/icons';
import { createAsync } from '@solidjs/router';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { VideoSection } from '../../components/layouts/video-section';
import { createContentfulRequest } from '../content/[model]/_client';
import type { JSX } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

export default function AboutPage() {
	const team = createAsync(async () => getPersonList('executive-team'), { deferStream: true });

	return (
		<>
			<Title>About Troon | Troon</Title>

			<NoHydration>
				<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/about/headquarters.jpeg`}>
					<Heading as="h1" class="text-center text-white">
						About Troon
					</Heading>
				</Hero>
			</NoHydration>

			<Page>
				<Container>
					<Section>
						<VideoSection
							src={`${getConfigValue('CDN')}/digital/video/management-services/overview/index.m3u8`}
							poster={`${getConfigValue('IMAGE_HOST')}/digital/video/troon-approach-20240926.jpg`}
						>
							<NoHydration>
								<Heading as="h2">Strategic solutions for today’s golf businesses.</Heading>
								<p>
									Troon started as one facility in 1990 and has since grown to become the world’s largest professional
									club management company. We provide services to 900+ locations while also managing various amenities,
									such as racquet sports, aquatics, fitness, food & beverage, lodging and more. We have grown our
									company by listening to and understanding the specific opportunities and challenges of our clients,
									then customizing our approach to achieve success together. This is accomplished by providing a
									foundation comprised of the best talent and resources in the industry and cultivating the creativity
									of our associates worldwide. Our successful culture, combined with over 30 years of experience, give
									us the opportunity to develop the strategies, resources and talent that allow our clients to excel in
									this competitive industry.
								</p>
							</NoHydration>
						</VideoSection>
					</Section>
				</Container>

				<NoHydration>
					<Container>
						<HorizontalRule />
					</Container>

					<Section>
						<Container class="flex flex-col items-center gap-8 text-center">
							<Heading as="h2" size="h3">
								Our Brands
							</Heading>
							<p class="max-w-4xl">
								Comprised of four primary brands committed to providing best-in-class hospitality and club management
								services, as well as innovative brands beyond golf, Troon has the resources and talent to develop the
								strategies that allow clients to excel in the competitive world of golf and club operations.
							</p>
							<div class="flex flex-row flex-wrap items-center gap-8">
								<Icon name="logo" class="w-96" />
							</div>
						</Container>
					</Section>
				</NoHydration>

				<div class="-mb-16 border-y border-y-neutral md:-mb-24">
					<Carousel itemClass="w-fit lg:w-fit min-w-fit border-s border-neutral last:border-e">
						<For each={brands}>
							{(brand) => (
								<Carousel.Item>
									<Switch>
										<Match when={brand.img.endsWith('svg')}>
											<div
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="pointer-events-none flex h-40 w-64 grow items-center justify-center px-8"
											>
												<img src={`/assets/logos/${brand.img}`} alt={`Logo for ${brand.title}`} class="w-full" />
											</div>
										</Match>
										<Match when>
											<div class="px-16 py-8">
												<Picture
													class="pointer-events-none w-fit"
													src={`${getConfigValue('IMAGE_HOST')}/digital/about/brands/${brand.img}`}
													width={120}
													height={120}
													sizes="6rem"
													mode="contain"
													crop="center"
													alt={`Logo for ${brand.title}`}
												/>
											</div>
										</Match>
									</Switch>
								</Carousel.Item>
							)}
						</For>
					</Carousel>
				</div>

				<NoHydration>
					<Section appearance="primary">
						<Container class="flex flex-col gap-8">
							<div class="flex justify-between">
								<Heading as="h2" size="h3">
									Senior Executives
								</Heading>
								<LinkButton appearance="current" href="/about/executive-team">
									View all<span class="sr-only"> of Troon’s Executive Team</span> <Icon name="chevron-right" />
								</LinkButton>
							</div>
							<ul class="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3 lg:grid-cols-5 lg:gap-x-8">
								<For each={team()?.fields.people.slice(0, 5)}>
									{(person) => (
										<li class="flex flex-col gap-2">
											<Picture
												src={person?.fields.photo?.fields.file?.url}
												width={200}
												height={280}
												sizes="(min-width 1024px) 20vw, (min-width: 768px) 33vw, 45vw"
												alt={person?.fields.name}
												loading="lazy"
												class="w-full rounded-md md:rounded"
												crop="face"
											/>
											<p class="flex flex-col gap-px">
												<span class="text-lg font-semibold">{person?.fields.name}</span>
												<span class="text-sm">{person?.fields.title}</span>
											</p>
										</li>
									)}
								</For>
							</ul>
						</Container>
					</Section>

					<Section>
						<Container class="flex flex-col gap-8">
							<Heading as="h2" size="h3">
								More from Troon
							</Heading>
							<div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
								<For each={moreFromTroon}>
									{(data) => (
										<article class="group relative flex shrink grow basis-full flex-col gap-4">
											<div class="overflow-hidden rounded">
												<Picture
													src={data.image}
													width={400}
													height={300}
													sizes="(min-width 1024px) 33vw, 95vw"
													alt=""
													// eslint-disable-next-line tailwindcss/no-arbitrary-value
													class="pointer-events-none aspect-[4/3] w-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
												/>
											</div>
											<div class="flex flex-col gap-1">
												<Heading as="h3" size="h5">
													{data.title}
												</Heading>
												<p class="text-sm">{data.content}</p>
											</div>
											<LinkButton
												href={data.url}
												class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0"
											>
												{data.linkText}
												<Icon name="chevron-right" />
											</LinkButton>
										</article>
									)}
								</For>
							</div>
						</Container>
					</Section>
				</NoHydration>
			</Page>
		</>
	);
}

const getPersonList = createContentfulRequest('person-list');

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const moreFromTroon: Array<{ image: string; title: string; content: string; url: string; linkText: JSX.Element }> = [
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/play-troon-programs.jpeg`,
		title: 'Play Troon Programs',
		content:
			'Troon is dedicated to growing the game of golf and has implemented initiatives and programs to help you and your family play more.',
		url: '/play-troon-programs',
		linkText: 'See the opportunities',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/mission-vision-values.jpg`,
		title: 'Our Mission, Vision, and Values',
		content:
			'We deliver memorable experiences that cultivate lasting connections fueled by a passion for enabling fun.',
		url: '/about/mission-vision-values',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon’s mission, vision, and values</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/founder-history.jpg`,
		title: 'Founder & History',
		content:
			'When Troon Founder Dana Garmany started Troon Golf 34 years ago, he never imagined he would revolutionize the golf business.',
		url: '/about/founder-history',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon’s Management Services</span>
			</>
		),
	},
];

const brands: Array<{ title: string; img: string; featured?: boolean }> = [
	{ title: 'Troon Golf', img: 'troon-golf.svg' },
	{ title: 'Troon International', img: 'troon-international.svg' },
	{ title: 'Troon Privé', img: 'troon-prive.svg' },
	{ title: 'CADDIEMASTER', img: 'caddie-master.png' },
	{ title: 'Casa Verde Golf', img: 'casa-verde.png' },
	{ title: 'Cliff Drysdale Tennis', img: 'cliff-drysdale-tennis.png' },
	{ title: 'ClubUp', img: 'club-up.png' },
	{ title: 'EventiveSports', img: 'eventive-sports.png' },
	{ title: 'ICON Management', img: 'icon-management.png' },
	{ title: 'Indigo Sports', img: 'indigo-sports.png' },
	{ title: 'PBI Tennis Specialists', img: 'pbi-tennis.png' },
	{ title: 'Real Food', img: 'real-food.png' },
	{ title: 'True Club Solutions', img: 'true-club-solutions.png' },
];
